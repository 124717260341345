<template>
  <div class="elearning-subject">
    <div class="mb-4">
      <h2
        class="font-weight-bold black--text"
        style="word-break: break-word"
      >
        {{ subject.name }}
      </h2>
    </div>

    <v-row>
      <v-col
        xl="8"
        lg="8"
        md="8"
        sm="12"
        cols="12"
      >
        <v-card class="pa-4 mb-8">
          <h4 class="font-weight-bold black--text mb-4">
            Informasi
          </h4>
          <div class="d-md-flex d-sm-block">
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              cols="12"
              class="px-0 py-4 pa-md-0 pa-lg-0 pa-xl-0"
            >
              <div>
                <div class="d-flex">
                  <v-card
                    class="rounded-lg d-flex align-center justify-center"
                    width="50"
                    height="50"
                    style="background:#7D2B4B"
                  >
                    <div class="d-flex justify-center">
                      <div class="">
                        <v-img
                          :src="icon.iconUser"
                          width="30"
                          height="30"
                          class=""
                        ></v-img>
                      </div>
                    </div>
                  </v-card>
                  <div class="ml-md-4 ml-2 align-center">
                    <span class="text-subtitle-2 font-weight-light">Oleh</span>
                    <h4 class="font-weight-bold black--text">
                      {{ subject.teacher.employee.name }}
                    </h4>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col
              xl="8"
              lg="8"
              md="8"
              sm="12"
              cols="12"
              class="pa-md-0"
            >
              <v-row>
                <v-col
                  md="3"
                  sm="6"
                  class="px-0"
                >
                  <div>
                    <div
                      class="d-md-flex d-block"
                    >
                      <v-card
                        class="rounded-lg d-flex align-center mb-2 mx-2-sm justify-center"
                        width="50"
                        height="50"
                        color="#F7B239"
                      >
                        <div class="d-flex justify-sm-center">
                          <div class="">
                            <v-img
                              :src="icon.iconChapter"
                              width="30"
                              height="30"
                              class=""
                            ></v-img>
                          </div>
                        </div>
                      </v-card>
                      <div
                        class="ml-md-2"
                      >
                        <p class="text-subtitle-2 font-weight-light mb-0">
                          Bab
                        </p>
                        <p class="font-weight-bold mb-0 black--text">
                          {{ subject.total_section }}
                        </p>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col
                  md="3"
                  sm="6"
                  class="px-0"
                >
                  <div>
                    <div
                      class="d-md-flex d-block"
                    >
                      <v-card
                        class="rounded-lg d-flex align-center mb-2 mx-2-sm justify-center"
                        width="50"
                        height="50"
                        color="#2197D8"
                      >
                        <div class="d-flex justify-sm-center">
                          <div class="">
                            <v-img
                              :src="icon.iconLesson"
                              width="30"
                              height="30"
                              class=""
                            ></v-img>
                          </div>
                        </div>
                      </v-card>
                      <div
                        class="ml-md-2"
                      >
                        <p class="text-subtitle-2 font-weight-light mb-0">
                          Materi
                        </p>
                        <p class="font-weight-bold mb-0 black--text">
                          {{ subject.total_lesson }}
                        </p>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col
                  md="3"
                  sm="6"
                  class="px-0"
                >
                  <div>
                    <div
                      class="d-md-flex d-block"
                    >
                      <v-card
                        class="rounded-lg d-flex align-center mb-2 mx-2-sm justify-center"
                        width="50"
                        height="50"
                        color="#EE3E23"
                      >
                        <div class="d-flex justify-sm-center">
                          <div class="">
                            <v-img
                              :src="icon.iconQuiz"
                              width="30"
                              height="30"
                              class=""
                            ></v-img>
                          </div>
                        </div>
                      </v-card>
                      <div
                        class="ml-md-2"
                      >
                        <p class="text-subtitle-2 font-weight-light mb-0">
                          Latihan
                        </p>
                        <p class="font-weight-bold mb-0 black--text">
                          {{ subject.total_exercise }}
                        </p>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col
                  md="3"
                  sm="6"
                  class="px-0"
                >
                  <div>
                    <div
                      class="d-md-flex d-block"
                    >
                      <v-card
                        class="rounded-lg d-flex align-center mb-2 mx-2-sm justify-center"
                        width="50"
                        height="50"
                        color="#D498C4"
                      >
                        <div class="d-flex justify-sm-center">
                          <div class="">
                            <v-img
                              :src="icon.iconExam"
                              width="30"
                              height="30"
                              class=""
                            ></v-img>
                          </div>
                        </div>
                      </v-card>
                      <div
                        class="ml-md-2"
                      >
                        <p class="text-subtitle-2 font-weight-light mb-0">
                          Ujian
                        </p>
                        <p class="font-weight-bold mb-0 black--text">
                          {{ subject.total_exam }}
                        </p>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-card>
        <v-divider></v-divider>
        <div v-if="subject.section.length > 0">
          <div class="mb-4">
            <div
              v-for="sectionItem in subject.section"
              :key="sectionItem.name"
              class="mb-4"
            >
              <div

                class="my-6 d-flex justify-space-between align-center"
              >
                <span class="font-weight-bold black--text text-md-h6 text-caption-xs text-capitalize">
                  BAB {{ sectionItem.section_of }} - {{ sectionItem.name }}
                </span>
                <a

                  class="text-decoration-none d-flex primary--text text-md-subtitle-1 text-subtitle-2"
                  @click.prevent="editSection(sectionItem.uuid)"
                >
                  <v-icon
                    color="primary"
                    class="mr-1"
                  >
                    {{ icons.mdiSquareEditOutline }}
                  </v-icon>
                  <span class="d-none d-md-block">EDIT BAB</span>
                </a>
              </div>
              <v-row class="mb-4">
                <v-col
                  v-for="chapterLessonItem in sectionItem.chapterLesson"

                  :key="chapterLessonItem.title"
                  md="4"
                  sm="6"
                  cols="12"
                >
                  <v-card
                    height="220"
                  >
                    <v-card-title class="pa-2">
                      <v-spacer></v-spacer>
                      <v-menu
                        offset-y
                        nudge-bottom="5"
                        nudge-left="60"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="black"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon size="20">
                              {{ icons.mdiDotsVertical }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item :to="{ name: 'elearning-teacher-subject-section-chapter-lesson-edit',params: { uuid: chapterLessonItem.uuid }} ">
                            <v-list-item-title>
                              <div class="d-flex">
                                <div>
                                  <v-img
                                    width="16"
                                    src="@/assets/icons/edit.svg"
                                  ></v-img>
                                </div>

                                <small class="ml-2">Edit</small>
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                          <a
                            @click.prevent="confirmDestroy(chapterLessonItem.uuid)"
                          >
                            <v-list-item>
                              <v-list-item-title>
                                <div class="d-flex btn-delete">
                                  <div>
                                    <v-img
                                      width="16"
                                      src="@/assets/icons/trash-red.svg"
                                    ></v-img>
                                  </div>

                                  <small class="ml-2">Hapus</small>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                          </a>
                        </v-list>
                      </v-menu>
                    </v-card-title>
                    <v-card-text class="pa-4 text-center">
                      <router-link
                        :to="{ name:'elearning-teacher-subject-section-chapter-lesson',params:{ uuid: chapterLessonItem.uuid}}"
                        class="text-decoration-none"
                      >
                        <div>
                          <v-img
                            width="50"
                            :src="chapterLessonItem.icon.icon"
                            class="mx-auto"
                          ></v-img>
                        </div>
                        <div class="mt-4">
                          <span class="font-weight-light black--text text-capitalize">{{ chapterLessonItem.chapter_of }} . {{ chapterLessonItem.name }}</span>
                        </div>
                      </router-link>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  v-for="chapterExerciseItem in sectionItem.chapterExercise"
                  :key="chapterExerciseItem.name"
                  md="4"
                >
                  <v-card
                    class="elevation-0"
                    height="220"
                    color="#EE3E23"
                  >
                    <v-card-title class="pa-2">
                      <v-spacer></v-spacer>
                      <v-menu
                        offset-y
                        nudge-bottom="5"
                        nudge-left="60"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="white"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon size="20">
                              {{ icons.mdiDotsVertical }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item :to="{ name: 'elearning-teacher-subject-section-chapter-exercise-edit',params: { uuid: chapterExerciseItem.uuid }} ">
                            <v-list-item-title>
                              <div class="d-flex">
                                <div>
                                  <v-img
                                    width="16"
                                    src="@/assets/icons/edit.svg"
                                  ></v-img>
                                </div>

                                <small class="ml-2">Edit</small>
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                          <a
                            @click.prevent="confirmDestroy(chapterExerciseItem.uuid)"
                          >
                            <v-list-item>
                              <v-list-item-title>
                                <div class="d-flex btn-delete">
                                  <div>
                                    <v-img
                                      width="16"
                                      src="@/assets/icons/trash-red.svg"
                                    ></v-img>
                                  </div>

                                  <small class="ml-2">Hapus</small>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                          </a>
                        </v-list>
                      </v-menu>
                    </v-card-title>
                    <v-card-text class="text-center">
                      <router-link :to="{ name: 'elearning-teacher-subject-section-chapter-exercise',params: { uuid: chapterExerciseItem.details.uuid}}">
                        <div>
                          <v-img
                            width="50"
                            src="@/assets/icons/medal-exercise.svg"
                            class="mx-auto"
                          ></v-img>
                        </div>
                        <div
                          class="mt-4"
                        >
                          <h3 class="white--text">
                            Latihan
                          </h3>
                          <span class="font-weight-light white--text text-capitalize">{{ chapterExerciseItem.name }}</span>
                        </div>
                      </router-link>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  v-for="chapterExamItem in sectionItem.chapterExam"
                  :key="chapterExamItem.name"
                  md="4"
                >
                  <v-card
                    class="elevation-0"
                    height="220"
                    color="#D498C4"
                  >
                    <v-card-title class="pa-2">
                      <v-spacer></v-spacer>
                      <v-menu
                        offset-y
                        nudge-bottom="5"
                        nudge-left="60"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="white"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon size="20">
                              {{ icons.mdiDotsVertical }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item :to="{ name: 'elearning-teacher-subject-section-chapter-exam-edit',params: { uuid: chapterExamItem.uuid }} ">
                            <v-list-item-title>
                              <div class="d-flex">
                                <div>
                                  <v-img
                                    width="16"
                                    src="@/assets/icons/edit.svg"
                                  ></v-img>
                                </div>

                                <small class="ml-2">Edit</small>
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                          <a
                            @click.prevent="confirmDestroy(chapterExamItem.uuid)"
                          >
                            <v-list-item>
                              <v-list-item-title>
                                <div class="d-flex btn-delete">
                                  <div>
                                    <v-img
                                      width="16"
                                      src="@/assets/icons/trash-red.svg"
                                    ></v-img>
                                  </div>

                                  <small class="ml-2">Hapus</small>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                          </a>
                        </v-list>
                      </v-menu>
                    </v-card-title>
                    <v-card-text class="text-center">
                      <router-link
                        class="text-decoration-none"
                        :to="{name: 'elearning-teacher-subject-section-chapter-exam',params: {uuid: chapterExamItem.details.uuid}}"
                      >
                        <div>
                          <v-img
                            width="50"
                            src="@/assets/icons/medal-exam.svg"
                            class="mx-auto"
                          ></v-img>
                        </div>
                        <div
                          class="mt-4"
                        >
                          <h3 class="white--text">
                            Ujian
                          </h3>
                          <span class="font-weight-light white--text text-capitalize">{{ chapterExamItem.name }}</span>
                        </div>
                      </router-link>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <a
                    style="text-decoration:none;"
                    @click="addFeature(sectionItem)"
                  >
                    <v-card
                      color="#F5F5F5"
                      class="pa-4 elevation-0 d-flex align-center justify-center"
                      style="border: 1px dashed #C4C4C4;"
                      height="220"
                    >
                      <v-card-text class="text-center">
                        <v-icon large>
                          {{ icons.mdiPlus }}
                        </v-icon>
                        <div class="mt-2">
                          <p class="mb-0">Tambah</p>
                          <span>Materi, Latihan, Ujian</span>
                        </div>
                      </v-card-text>
                    </v-card>
                  </a>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
          </div>
          <div
            v-if="subject.section.length >= 1"
            class="d-flex justify-center"
          >
            <v-btn
              class="my-3"
              color="primary"
              large
              @click="showFormAdd()"
            >
              Tambah Bab Baru
            </v-btn>
          </div>
        </div>
        <div
          v-if="isEmpty"
          class="pa-4 d-flex justify-center"
        >
          <div class="text-center">
            <v-img
              src="@/assets/images/vector/empty.svg"
              width="256"
              height="215"
              class="mb-4"
            ></v-img>
            <p>Belum ada materi</p>
            <v-btn
              color="primary"
              large
              @click="showFormAdd()"
            >
              Tambah Bab Baru
            </v-btn>
          </div>
        </div>
      </v-col>

      <v-col v-if="listLessonHours">
        <v-card>
          <v-card-text>
            <h3
              class="text-h6 font-weight-normal black--text mb-4"
              style="line-height: 1.7rem !important"
            >
              Siswa yang mengikuti E-Learning
            </h3>
            <div class="mb-4">
              <div class="d-flex mb-3">
                <div class="d-flex">
                  <v-img
                    src="@/assets/icons/teacher.svg"
                    width="18"
                    class="mr-4"
                  ></v-img>
                  <span
                    v-if="className"
                  >Kelas: {{ className }}</span>
                  <span v-else>Belum ada kelas</span>
                </div>
              </div>
              <div class="d-flex mb-3">
                <div class="d-flex">
                  <v-img
                    src="@/assets/icons/profile-user.svg"
                    width="18"
                    class="mr-4"
                  ></v-img>
                  <span>{{ students.length }} Siswa/i</span>
                </div>
              </div>
              <div class="d-flex mb-3">
                <div class="d-flex">
                  <v-img
                    src="@/assets/icons/calendar.svg"
                    width="18"
                    class="mr-4"
                  ></v-img>
                  <span>{{ dateNow.toISOString().replace('T', ' ').substr(0, 10) }}</span>
                </div>
              </div>
            </div>
            <div class="py-2 pb-6">
              <v-btn
                color="primary"
                outlined
                class="d-block"
                width="100%"
                @click="showFormAttendance()"
              >
                Absensi
              </v-btn>
            </div>
            <v-divider></v-divider>
            <div>
              <div
                v-if="!isLoadingListAtt"
                class="py-4 px-0 mt-2"
              >
                <div
                  v-if="listAttStudents.length < 1"
                  class="pa-4 mt-2 d-flex justify-center"
                >
                  <div class="text-center">
                    <v-img
                      src="@/assets/images/vector/empty-2.svg"
                      width="161"
                      height="118"
                      class="mx-auto"
                    ></v-img>
                    <p class="ma-0">
                      Belum ada yang mengikuti e-learning
                    </p>
                  </div>
                </div>
                <div v-if="listAttStudents.length > 0">
                  <div
                    v-for="listAttStudent in listAttStudents"
                    :key="listAttStudent.uuid"
                    class="d-flex justify-space-between align-center pb-6"
                  >
                    <div class="d-flex justify-start align-center">
                      <div>
                        <v-badge
                          bottom
                          color="success"
                          overlap
                          offset-x="12"
                          offset-y="12"
                          class="me-3"
                          dot
                        >
                          <v-avatar
                            size="40px"
                            color="primary"
                            class="v-avatar-light-bg primary--text"
                          >
                            <v-img
                              src="@/assets/images/avatars/1.png"
                            ></v-img>
                          </v-avatar>
                        </v-badge>
                      </div>
                      <div class="student-name">
                        <span>{{ listAttStudent.student.full_name }}</span>
                      </div>
                    </div>
                    <div class="text-right">
                      <v-select
                        :items="attList"
                        :value="listAttStudent.status.replace(/^\w/, (c) => c.toUpperCase())"
                        class="attendance-input pt-0"
                        outlined
                        dense
                        color="primary"
                        @change="updateAttendance(listAttStudent.uuid, $event)"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div
                    class="pt-4 px-0 text-center mt-2"
                    style="cursor:pointer;"
                  >
                    <span class="text-subtitle-1 d-inline-block primary--text pr-1">
                      Tampilkan Semua
                    </span>
                    <v-icon
                      color="primary"
                      class="d-inline-block showToggle"
                    >
                      {{ icons.mdiChevronDown }}
                    </v-icon>
                  </div>
                </div>
              </div>
              <v-skeleton-loader
                v-else
                v-bind="skeleton"
                type="list-item-avatar-three-line"
                class="mt-6"
              ></v-skeleton-loader>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-else></v-col>
    </v-row>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :text-button="dialog ==='add'? 'Tambah': 'Update'"
      @close-button="modalDialog = false"
      @close-modal="modalDialog = false"
      @handler-button="handlerButton"
    >
      <template
        v-if="dialog ==='add'"
        v-slot:header
      >
        Tambah Bab
        <v-alert
          text
          color="info"
          dark
          class="mt-4 mb-0"
          width="100%"
        >
          <p class="font-weight-bold">
            PERHATIAN !!!
          </p>
          <span class="font-weight-light text-caption">Masukkan BAB terlebih dahulu untuk membuat materi pelajaran baru</span>
        </v-alert>
      </template>
      <template
        v-else-if="dialog ==='edit'"
        v-slot:header
      >
        Edit Bab
      </template>
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="section.section_of"
            label="Bab Ke"
            type="number"
            outlined
            dense
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="section.name"
            label="Judul Bab"
            outlined
            dense
            type="text"
          ></v-text-field>
        </div>
      </template>
      <!-- <template v-slot:footer>
        <v-btn
          v-if="dialog=='add'"
          color="primary"
          dense
          large
          :loading="isLoadingButton"
          @click="addSection()"
        >
          Tambah
        </v-btn>
        <v-btn
          v-if="dialog=='edit'"
          color="primary"
          dense
          large
          :loading="isLoadingButton"
          @click="updateSection(section.uuid)"
        >
          Update
        </v-btn>
        <v-btn
          color="primary"
          dense
          outlined
          large
          @click="modalDialog = false"
        >
          Batal
        </v-btn>
      </template> -->
    </ModalDialog>
    <ModalDialog
      :visible="modalDialogFeature"
      :width="width"
      :hidden-actions="false"
      header="Tambah Data"
      @close="modalDialogFeature = false"
      @close-modal="modalDialogFeature = false"
    >
      <template v-slot:body>
        <v-card
          outlined
          v-for="chapterType, index in chapterTypes"
          :key="index"
          class="mb-2 elevation-0 rounded pa-4"
        >
          <router-link
            :to="{ name: chapterType.url ,params: { uuid: section.uuid}}"
            class="text-decoration-none"
          >
            <div
              class="d-flex justify-center align-center"
            >
              <div class="me-4">
                <v-img
                  :src="chapterType.icon"
                  width="50"
                ></v-img>
              </div>
              <span class="">
                {{ chapterType.title }}
              </span>
            </div>
          </router-link>
        </v-card>

      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDialogAttendance"
      :width="1000"
      :hidden-actions="false"
      @close-button="modalDialogAttendance = false"
      @close-modal="modalDialogAttendance = false"
    >
      <template
        v-slot:header
      >
        Absensi Siswa/i yang Mengikuti E-Learning
      </template>
      <template v-slot:body>
        <div class="d-flex justify-start align-center px-2">
          <div class="d-flex mb-3 mr-10">
            <div class="d-flex">
              <v-img
                src="@/assets/icons/teacher.svg"
                width="18"
              ></v-img>
              <span class="ml-4">Kelas: {{ className }}</span>
            </div>
          </div>
          <div class="d-flex mb-3 mr-10">
            <div class="d-flex">
              <v-img
                src="@/assets/icons/profile-user.svg"
                width="18"
              ></v-img>
              <span class="ml-4">{{ students.length }} Siswa/i</span>
            </div>
          </div>
          <div class="d-flex mb-3 mr-10">
            <div class="d-flex">
              <v-img
                src="@/assets/icons/calendar.svg"
                width="18"
              ></v-img>
              <span class="ml-4">{{ dateNow.toISOString().replace('T', ' ').substr(0, 10) }}</span>
            </div>
          </div>
        </div>
        <v-row class="px-2 pt-2">
          <v-col cols="8">
            <div>
              <v-text-field
                v-model="search"
                label="Cari"
                outlined
                dense
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex demo-space-x flex-wrap justify-end">
              <v-checkbox
                hide-details
                label="Hadir Semua"
                class="mr-0 text-subtitle-2"
                @change="checkAll($event)"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>
        <div class="mb-6 custom-table">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">
                    Nama Siswa/i
                  </th>
                  <th class="text-uppercase">
                    Kehadiran
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(student, index) in searchStudent"
                  :key="index"
                >
                  <td>
                    <div class="d-flex justify-start align-center">
                      <div>
                        <v-badge
                          bottom
                          color="success"
                          overlap
                          offset-x="12"
                          offset-y="12"
                          class="me-3"
                          dot
                        >
                          <v-avatar
                            size="40px"
                            color="primary"
                            class="v-avatar-light-bg primary--text"
                          >
                            <v-img
                              src="@/assets/images/avatars/1.png"
                            ></v-img>
                          </v-avatar>
                        </v-badge>
                      </div>
                      <span>{{ student.name }}</span>
                    </div>
                  </td>
                  <td>
                    <template>
                      <v-radio-group
                        v-model="selectedRadios['att_'+student.uuid]"
                        class="mt-0"
                        hide-details
                      >
                        <div class="d-flex flex-wrap demo-space-x">
                          <v-radio
                            v-for="(attendance) in attendances"
                            :key="attendance.value"
                            class="mr-10"
                            color="primary"
                            :label="attendance.title"
                            :value="attendance.value"
                            @change="selectedRadio(attendance, student.uuid)"
                          ></v-radio>
                        </div>
                      </v-radio-group>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn
          v-if="searchStudent.length > 0"
          color="primary"
          dense
          large
          :disabled="isLoadingButton"
          :loading="isLoadingButton"
          @click="addAttendance()"
        >
          Simpan
        </v-btn>
        <v-btn
          color="primary"
          dense
          outlined
          large
          @click="modalDialogAttendance = false"
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronDown, mdiDotsVertical, mdiPlus, mdiSquareEditOutline } from '@mdi/js'
import iconChapter from '@/assets/icons/white/chapter.svg'
import iconExam from '@/assets/icons/white/exam.svg'
import iconQuiz from '@/assets/icons/white/quiz.svg'
import iconLesson from '@/assets/icons/white/theory.svg'
import iconUser from '@/assets/icons/white/user.svg'
import iconFeatureAward from '@/assets/icons/color/award.svg'
import iconFeatureBook from '@/assets/icons/color/book.svg'
import iconFeaturePaper from '@/assets/icons/color/exam.svg'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'

export default {
  name: 'Section',
  components: {
    ModalDialog,
    ConfirmDialog,
  },
  data() {
    return {
      skeleton: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 3,
        types: {},
      },
      subject: {
        uuid: '',
        name: '',
        section_uuid: '',
        section: {
          uuid: '',
          chapter: {
            uuid: '',
          },
        },
        teacher: {
          employee: {
            name: '',
          },
        },
      },
      sections: [],
      section: {
        subject_uuid: '',
        uuid: '',
        name: '',
        section_of: '',
        chapters: [],
        chapter: {
          uuid: '',
        },
      },
      chapters: [],
      chapterLesson: [],
      chapterExercise: [],
      chapterExam: [],
      chapter: {
        uuid: '',
        name: '',
        chapter_of: '',
      },
      chapterTypes: [
        { icon: iconFeaturePaper, title: 'Tambah Materi', url: 'elearning-teacher-subject-section-chapter-lesson-add' },
        {
          icon: iconFeatureBook,
          title: 'Tambah Latihan',
          url: 'elearning-teacher-subject-section-chapter-exercise-add',
        },
        { icon: iconFeatureAward, title: 'Tambah Ujian', url: 'elearning-teacher-subject-section-chapter-exam-add' },
      ],
      lessons: [],
      exercises: [],
      exercise: {
        title: '',
        deadline: '',
        instruction: '',
      },
      exams: [],
      icon: {
        iconUser,
        iconChapter,
        iconExam,
        iconQuiz,
        iconLesson,
        iconFeatureBook,
        iconFeaturePaper,
        iconFeatureAward,
      },
      dialog: '',
      confirmDialog: false,
      icons: {
        mdiSquareEditOutline,
        mdiPlus,
        mdiDotsVertical,
        mdiChevronDown,
      },
      dashboards: [
        {
          icon: iconChapter,
          title: 'Chapter',
          total: '4',
          color: '#F7B239',
        },
        {
          icon: iconLesson,
          title: 'Lesson',
          total: '48',
          color: '#2197D8',
        },
        {
          icon: iconQuiz,
          title: 'Exercise',
          total: '20',
          color: '#EE3E23',
        },
        {
          icon: iconExam,
          title: 'Ujian',
          total: '16',
          color: '#D498C4',
        },
      ],
      selectedRadios: {},
      attendanceStudent: {
        subject_uuid: '',
        absent: '',
        student_uuid: [],
        status: [],
      },
      modalDialog: false,
      modalDialogAttendance: false,
      modalDialogFeature: false,
      width: 500,
      isLoadingButton: false,
      isLoadingListAtt: true,
      attendances: [
        { title: 'Hadir', value: 'hadir' },
        { title: 'Alfa', value: 'alfa' },
        { title: 'Sakit', value: 'sakit' },
        { title: 'Izin', value: 'izin' },
        { title: 'Lainnya', value: 'lainnya' },
      ],
      attList: ['Hadir', 'Alfa', 'Sakit', 'Lainnya'],
      days: ['minggu', 'senin', 'selasa', 'rabu', 'kamis', 'jumat', 'sabtu'],
      dateNow: new Date(),
      date: '',
      day: '',
      school: '',
      school_uuid: '',
      user_uuid: '',
      students: [],
      classUuid: '',
      className: '',
      listLessonHours: [],
      listAttStudents: [],
      search: '',
      isEmpty: false,
      filtered: [],
      formValid: false,
    }
  },
  computed: {
    searchStudent() {
      return this.setStudent()
    },
  },
  watch: {
    section: {
      handler() {
        const valid = []
        const requiredField = ['name', 'section_of']
        Object.entries(this.section).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
  },
  async mounted() {
    this.getSchoolUuid()
    await this.getSubject(this.$route.params.uuid)
    await this.getSchedule()
    await this.getHours()
    await this.getAttStudent()
    await this.listStudents()
  },
  methods: {
    setStudent() {
      this.filtered = this.students.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()))

      this.listAttStudents.forEach(itemAtt => {
        this.filtered = this.filtered.filter(val => !itemAtt.student.uuid.includes(val.uuid))
      })
      this.isLoadingListAtt = false

      return this.filtered
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },

    checkAll(isTrue) {
      if (isTrue) {
        this.date = `${this.dateNow.getFullYear()}-${`0${this.dateNow.getMonth() + 1}`.slice(
          -2,
        )}-${`0${this.dateNow.getDate()}`.slice(-2)} ${`0${this.dateNow.getHours()}`.slice(
          -2,
        )}:${`0${this.dateNow.getMinutes()}`.slice(-2)}`
        this.attendanceStudent.subject_uuid = this.$route.params.uuid
        this.attendanceStudent.absent = this.date

        this.students.forEach(item => {
          if (this.attendanceStudent.student_uuid.includes(item.uuid)) {
            this.$set(this.selectedRadios, `att_${item.uuid}`, '')
          } else {
            this.attendanceStudent.student_uuid.push(item.uuid)
            this.attendanceStudent.status.push('hadir')
          }
          this.$set(this.selectedRadios, `att_${item.uuid}`, 'hadir')
        })
      } else {
        this.students.forEach(item => {
          this.$set(this.selectedRadios, `att_${item.uuid}`, '')
        })

        this.attendanceStudent.student_uuid = []
        this.attendanceStudent.status = []
      }
    },

    async selectedRadio(data, uuid) {
      this.date = `${this.dateNow.getFullYear()}-${`0${this.dateNow.getMonth() + 1}`.slice(
        -2,
      )}-${`0${this.dateNow.getDate()}`.slice(-2)} ${`0${this.dateNow.getHours()}`.slice(
        -2,
      )}:${`0${this.dateNow.getMinutes()}`.slice(-2)}`
      this.attendanceStudent.subject_uuid = this.$route.params.uuid
      this.attendanceStudent.absent = this.date

      if (this.attendanceStudent.student_uuid.includes(uuid)) {
        this.attendanceStudent.student_uuid = []
        this.attendanceStudent.status = []
        this.attendanceStudent.student_uuid.push(uuid)
        this.attendanceStudent.status.push(data.value)
      } else {
        this.attendanceStudent.student_uuid.push(uuid)
        this.attendanceStudent.status.push(data.value)
      }
    },

    async addAttendance() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add('attendance', this.attendanceStudent).then(
        ({ data }) => {
          this.isLoadingListAtt = true
          this.getAttStudent()
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.modalDialogAttendance = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },

    async updateAttendance(uuid, data) {
      this.attendanceStudent.status = data.toLowerCase()
      await this.$services.ApiServices.update('attendance', { status: this.attendanceStudent.status }, uuid).then(
        ({ data }) => {
          this.isLoadingListAtt = true
          this.getAttStudent()
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          console.error(err)
        },
      )
    },

    async getAttStudent() {
      await this.$services.ApiServices.get('attendance', this.$route.params.uuid).then(({ data }) => {
        this.listAttStudents = data
      })
      this.isLoadingListAtt = false
    },

    async getHours() {
      this.day = this.days[this.dateNow.getDay()]
      await this.$services.ApiServices.get('hours', this.school_uuid).then(({ data }) => {
        this.lessonHours = data.data
      })
    },

    async getSchedule(params = {}) {
      this.day = this.days[this.dateNow.getDay()]
      await this.$services.ApiServices.list('schedule', {
        ...params,
        school_uuid: this.school_uuid,
        subject_uuid: this.$route.params.uuid,
      }).then(({ data }) => {
        this.listLessonHours = data.data
        this.className = this.listLessonHours.class.name
        this.classUuid = this.listLessonHours.class.uuid
      })
    },

    async listStudents(params = {}) {
      await this.$services.ApiServices.list('student', {
        ...params,
        per_page: 'all',
        school_uuid: this.school_uuid,
        class: this.classUuid,
      }).then(({ data }) => {
        this.students = data.data
        this.students.forEach(item => {
          this.$set(this.selectedRadios, `att_${item.uuid}`, '')
        })
      })
    },

    async getSubject(uuid) {
      await this.$services.ApiServices.get('subject', uuid).then(
        ({ data }) => {
          this.subject = data.data
          this.sectionItem = this.subject.section.sort((a, b) => a.section_of - b.section_of)
          this.isEmpty = false
          if (this.sectionItem.length < 1) {
            this.isEmpty = true
          }
          this.sectionItem = this.sectionItem.map(section => {
            section.chapterLesson = []
            section.chapter.forEach(item => {
              if (item.type === 'lesson' && item.details !== null) {
                section.chapterLesson.push(item)
              }
            })
            section.chapterExercise = []

            section.chapter.forEach(item => {
              if (item.type === 'exercise' && item.details !== null) {
                section.chapterExercise.push(item)
              }
            })
            section.chapterExam = []
            section.chapter.forEach(item => {
              if (item.type === 'exam' && item.details !== null) {
                section.chapterExam.push(item)
              }
            })

            return section
          })
        },
        err => console.error(err),
      )
    },
    showFormAdd() {
      this.dialog = 'add'
      this.section.section_of = ''
      this.section.name = ''
      this.modalDialog = true
      this.section.subject_uuid = this.$route.params.uuid
    },

    showFormAttendance() {
      this.modalDialogAttendance = true
    },

    async addSection() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add('section', this.section).then(
        ({ data }) => {
          this.section = data.data
          this.showSnackbar({
            text: 'Add Section Has Successfully',
            color: 'success',
          })
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.getSubject(this.$route.params.uuid)
      this.isLoadingButton = false
    },
    async editSection(uuid) {
      this.dialog = 'edit'
      this.section.uuid = uuid
      this.modalDialog = true
      await this.$services.ApiServices.get('section', uuid).then(({ data }) => {
        this.section = data.data
      })
    },
    async updateSection() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update('section', this.section, this.section.uuid).then(
        ({ data }) => {
          this.section = data.data
          this.showSnackbar({
            text: 'Update Section Has Successfully',
            color: 'success',
          })
          this.getSubject(this.$route.params.uuid)
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },
    async addFeature(sectionItem) {
      this.section.uuid = sectionItem.uuid
      this.modalDialogFeature = true
    },
    handlerButton() {
      if (this.dialog === 'add') this.addSection()
      else this.updateSection()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.chapter.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('chapter', this.chapter.uuid).then(
        ({ data }) => {
          this.chapter = data.data
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.getSubject(this.$route.params.uuid)
      this.confirmDialog = false
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.showToggle {
  transition: transform 0.3s ease-in-out !important;
}

.showToggle.rotate {
  transform: rotate(180deg);
}

.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0 !important;
}

.custom-table {
  height: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.attendance-input > .v-input__control > .v-input__slot {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 700 !important;
  /* max-width: 30% !important; */
}

.attendance-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > input {
  width: 0 !important;
}

.attendance-input
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-select__selections
  > .v-select__selection--comma {
  overflow: inherit !important;
  margin-left: 0 !important;
}

.attendance-input > .v-input__control > .v-input__slot > fieldset {
  border: none !important;
}

.attendance-input > .v-input__control > .v-input__slot > .v-select__slot > .v-input__append-inner {
  padding-left: 0 !important;
}

.attendance-input
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-input__append-inner
  > .v-input__icon
  > span
  > svg {
  margin-right: 0 !important;
}

.attendance-input > .v-input__control > .v-text-field__details {
  display: none !important;
}
.btn-delete {
  color: #e30000 !important;
}
.student-name {
  width: 100%;
  max-width: 150px;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
